import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { menuLinks } from "../common/pageUtils"
import {ImageModule} from "../../modules/Image_Module"
import NoImage from "../../images/no-image.png"

const RelatedServices = (props) => {

    return (
        <React.Fragment>
            {
                props.Data && 
                <div className="related-service">
                    <h2>Related Services</h2>
                    {
                        props.Data.length > 0 && props.Data.map((item) => {
                            var menu_link = "javascript:;";
                            if(item){
                                menu_link = menuLinks(item);
                            }
                            return(
                                <Link to={menu_link}>
                                    <div className="service-wrapper">
                                        <div className="service-img img-zoom">
                                            {item.Image ? 
                                                <ImageModule ImageSrc={item.Image} altText={item.Label + " - Strettons"} ggfx_results={item.ggfx_results} imagename="all-menus.Image.small_image" strapi_id={item.id} /> 
                                                :
                                                <img src={NoImage} alt="img" />
                                            }
                                        </div>
                                        <div className="service-info">
                                            <h2>{item.Label}
                                                <i className="icon-slide"></i>
                                            </h2>
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }                    
                </div>
            }
           
        </React.Fragment>
    );
};
export default RelatedServices;

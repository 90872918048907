import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import $ from "jquery"
import { useLocation, useParams } from "@reach/router" 
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "../../PropertyDetails/Joint/Joint.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
    import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import HTMLReactParser from 'html-react-parser';
import {ImageModule} from "../../../modules/Image_Module"

import ScrollAnimation from "react-animate-on-scroll"

// Header component

const AccordionModule = ({Module, className,pageName}) => {
    const location =  useLocation();
    const params = new URLSearchParams(location.search);
    const get_read_more_id = params.get('readmore');
    const [activeId, setActiveId] = useState(get_read_more_id);

    useEffect(()=>{
        if(activeId){
            setTimeout(function(){
                $('html, body').animate({
                  scrollTop: $("#"+activeId)?.offset()?.top - 200
                }, 500);
            }, 1200)
        }
        
    },[])

    function ContextAwareToggle({ children, eventKey, callback }) {
        
        setActiveId(eventKey);
        const currentEventKey = useContext(AccordionContext)

        const decoratedOnClick = useAccordionToggle(
            eventKey,() => callback && callback(eventKey)
        )

        const isCurrentEventKey = currentEventKey === eventKey

        return (
            <button
                type="button"
                className={
                    isCurrentEventKey ? "active_card inactive_card" : "inactive_card"
                }
                onClick={decoratedOnClick}
            >
                {children}
                <i
                    className={
                        isCurrentEventKey ? "minus-circle " : "plus-circle"
                    }
                ></i>
            </button>
        )
    }

    //console.log("Module ===>", pageName);
    return (
        <React.Fragment>

            <section className={`accordion-wrapper ${className}`}>
                <Container>
                    <Row>
                        <Col>

                                <div className="accordion-section">
                                    {Module.Heading && 
                                    pageName === "Auction FAQs" ? <h2 className="auction-faq-heading">{Module.Heading}</h2> :
                                    <h3>{Module.Heading}</h3> }
                                    {Module.Content && <p>{Module.Content}</p> }

                                    <Accordion defaultActiveKey={activeId?.trim()}>
                                        {
                                            Module.Accordian_Module.length > 0 && Module.Accordian_Module.map((item, index) => {
                                                return(
                                                    <Card id={item?.Read_More_ID?.trim()}>
                                                        <div className="accordion-header card-header">
                                                            {item?.Read_More_ID ? 
                                                                <ContextAwareToggle eventKey={item?.Read_More_ID?.trim()} className="accordion-header">
                                                                    {item.Title}
                                                                </ContextAwareToggle>
                                                            :
                                                                <ContextAwareToggle eventKey={index.toString()} className="accordion-header">
                                                                    {item.Title}
                                                                </ContextAwareToggle>
                                                            }
                                                        </div>
                                                        
                                                        {item?.Read_More_ID ? 
                                                            <Accordion.Collapse eventKey={item?.Read_More_ID?.trim()}>
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    {item.Content && HTMLReactParser(item.Content)}
                                                                </div>
                                                            </Accordion.Collapse>
                                                        :
                                                            <Accordion.Collapse eventKey={index.toString()}>
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    {item.Content && HTMLReactParser(item.Content)}

                                                                    {item.Image && <p><ImageModule ImageSrc={item.Image} classNames="content_img" altText={item.Title + " - Strettons"} /></p> }
                                                                </div>
                                                            </Accordion.Collapse>
                                                        }

                                                        {/* {get_read_more_id === item?.Read_More_ID?.trim() ? 
                                                            <div className="collapse show">
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    {item.Content && HTMLReactParser(item.Content)}
                                                                </div>
                                                            </div>
                                                        :
                                                            <Accordion.Collapse eventKey={index.toString()}>
                                                                <div className="accordion-card accordion-card-first card-body">
                                                                    {item.Content && HTMLReactParser(item.Content)}
                                                                </div>
                                                            </Accordion.Collapse>
                                                        } */}
                                                    </Card>
                                                )
                                            })
                                        }
                                    </Accordion>
                                    {Module.Faq_Bottom_Text && HTMLReactParser(Module.Faq_Bottom_Text)}
                                </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};
export default AccordionModule;


import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import dateFormat from 'dateformat';

import RightsideSections from "../RightsideSections"
import ScrollAnimation from "react-animate-on-scroll";
import HTMLReactParser from 'html-react-parser';
import {ImageModule} from "../../../modules/Image_Module"
import { youtube_parser } from "../../common/utils";
import ReactPlayer from "react-player"
import AccordionModule from "../../InnerPage/Accordion/Accordion";

import "./Management.scss"
// Header component

const Management = (props) => {

    //console.log("insight_departments", props.news_details.insight_departments);

    var youtube_id = props.news_details.Embed_Video_URL ? youtube_parser(props.news_details.Embed_Video_URL) : ""
    //console.log("Content_and_Image", props?.news_details?.Content_and_Image)

    return (
        <React.Fragment>
            
                <section className="management-section" >
                    <Container>
                        <Row>
                            <Col>
                                
                                <div className="management-wrapper">
                                    <div className="management">
  
                                        {props.news_details && <>

                                            <span className="portfolio-btn">
                                                {/* {props.news_details.Service ? props.news_details.Service+' / ' :''} */}
                                                {props.news_details.insight_departments && props.news_details.insight_departments.length > 0 && props.news_details.insight_departments.map(item => item.Name+" / ")}

                                                {props.news_details.news_and_insights_category && props.news_details.news_and_insights_category.Name}
                                            </span>
                                            <h1>{props.news_details.Title}</h1>
                                            {props.news_details.Published_Date && <span className="sm-text">{dateFormat(props.news_details.Published_Date, "mmmm dd yyyy")}</span> }
                                            {props.news_details?.Author && <p>By {props.news_details.Author}</p> }

                                            {
                                                props.news_details.Client && 
                                                <div className={`client `}>
                                                    <h2>Client:</h2>
                                                    <span className="office">{props.news_details.Client}</span>
                                                </div>
                                            }
                                            
                                            {
                                                props.news_details.Property && 
                                                <div className="client property">
                                                    <h2>Property:</h2>
                                                    <span className="office">{props.news_details.Property}</span>

                                                    {/* <span className="office">
                                                    {
                                                        props.news_details.Select_Property.properties.map((item, index) => {
                                                            return(
                                                                <>{item.title}<br /></>
                                                            )
                                                        })
                                                    }
                                                    </span> */}
                                                    
                                                </div>
                                            }

                                            {
                                                props.news_details.Address && 
                                                <div className="client">
                                                    <h2>Address:</h2>
                                                    <span className="office">{props.news_details.Address}</span>
                                                </div>
                                            }

                                            {props.news_details.Highlight_Text && <p className="highlight_text">{props.news_details.Highlight_Text}</p>}

                                            {youtube_id && 
                                                <div className='insight_video_cnt'>
                                                    <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${youtube_id}`} title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </div>
                                            }                                           
                                            
                                            <div className="management-details">
                                                <div className="management-info management-news-details">
                                                    {
                                                        props.news_details.Content_and_Image && props.news_details.Content_and_Image.length > 0 ? 
                                                        props.news_details.Content_and_Image.map((item, i) => {
                                                            return(
                                                                <>
                                                                    {item.Content_Section && HTMLReactParser(item.Content_Section)}
                                                                    {item.Image_Section !== null && <ImageModule ImageSrc={item.Image_Section} classNames="content_img" altText={props.news_details.Title + " - Strettons"} /> }
                                                                </>
                                                            )
                                                        })
                                                        :                                                        
                                                        props.news_details.Content && HTMLReactParser(props.news_details.Content)
                                                    }

                                                    {props?.news_details?.Accordion_Component && props.news_details.Accordion_Component.length > 0 &&
                                                        <AccordionModule Module={{Accordian_Module: props.news_details.Accordion_Component}} className={""}/>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        }
              
                                    </div>
                                    <RightsideSections news_details={props.news_details}  />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            
        </React.Fragment>
    );
};
export default Management;
import React from "react"
import RightsideServices from "../InsightsDetails/RightsideServices"
import Peoples from "../RightSideComponent/Sections/Peoples"
import SubscribeSection from "../RightSideComponent/Sections/SubscribeSection"

const NewsRightSide = (props) => {
  return (
    <div className="management-contact insight_details">
      { props.news_details && props.news_details.teams && props.news_details.teams.length > 0 && <Peoples Data={props.news_details.teams}/>}
      { props.news_details.related_services && props.news_details.related_services.length > 0 && <RightsideServices Data={props.news_details.related_services} />}
      { props?.news_details?.Show_Subscribe_Updates && <SubscribeSection />}
    </div>
  );
};
export default NewsRightSide;